export const entityEn = {
  labels: {
    passport: {
      country: 'Country of Issue',
      passport: 'Passport Number',
    },
    driverLicense: {
      state: 'State or Territory',
      licenseNumber: 'Licence Number',
      cardNumber: 'Card Number',
    },
    medicareCard: {
      color: 'Medicare Card Colour',
      cardNumber: 'Medicare Card Number',
      position: 'Position on Card',
      expiry: 'Date of Expiry',
      displayName: 'Display Name on Card',
    },
  },
  personalInfo: {
    fullName: 'Full Name',
    name: 'Name',
    dob: 'Date of Birth',
    phoneNumber: 'Phone Number',
    email: 'Email address',
    currentAddress: 'Residential Address',
    previousAddress: 'Previous Residential Address',
    mailingAddress: 'Mailing Address',
  },
  title: {
    assignee: 'Assignee',
  },
  medicareCardColor: {
    G: 'Green',
    B: 'Blue',
    Y: 'Yellow',
  },
  amlManualStatus: {
    pass: 'Pass',
    fail: 'Fail',
  },
  alertAml: {
    title: 'You have identified a true positive match.',
    subTitle:
      'Do you want to update the rest of the AML matches to false  positives?',
    noKeep: 'No, keep as is',
    yesUpdate: 'Yes, update all',
  },
  amlStatusForm: {
    title: 'Resolve AML alert',
    subTitle:
      'This will assign a match status to the AML match, please review the data before proceeding.',
    selectLabel: 'AML status',
    commentLabel: 'Comment',
    cancel: 'Cancel',
    changeStatus: 'Resolve alert',
    approvalStatusSelectLabel: 'Approval status',
    approvalStatusPlaceholder: 'Select approval status',
    success: '{{count}} AML status matches has been successfully updated',
  },
  amlStatus: {
    potentialMatch: 'Potential Match',
    falsePositive: 'False Positive',
    truePositive: 'True Positive',
    unknown: 'Unknown',
  },
  assignEntity: {
    unassigned: 'Unassigned',
    assignTo: 'Assign profile to',
    placeholder: 'Search',
  },
  archiveEntity: {
    archive: 'Archive this entity',
    archiveTitle: 'This will change the profile status to archived.',
    archiveDescription:
      'The profile can still be found in searches, and may be unarchived at any time by updating details and re-running checks.',
    unarchive: 'Unarchive this entity',
    unarchiveTitle: 'This will revert the entity to its pre-archived state.',
    unarchiveCta: 'Unarchive entity',
    archiveCta: 'Archive entity',
    cancel: 'Cancel',
    comment: 'Comment',
    commentPlaceholder: 'Type your comment here',
    updatingStatus: 'Updating entity status',
    updatedSuccess: 'Entity status been successfully updated.',
  },
}
