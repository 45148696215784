import React, { useMemo, useState } from 'react'

import { FrankieButton } from 'frankify/src'

type DocumentType = {
  gallery: string[]
  className?: string
  handleExpand: (idx?: number) => void
  isImage?: boolean[]
  thumbnailTexts: {
    front?: string
    back?: string
    photoPage?: string
  }
}

export function DocumentImg({
  gallery,
  isImage = [],
  className = '',
  handleExpand,
  thumbnailTexts,
}: DocumentType) {
  const [imgIdx, setImgIdx] = useState(0)

  const handleImageChange = (isNext: boolean) => () => {
    if (isNext) {
      setImgIdx(prev => prev + 1)
    } else {
      setImgIdx(prev => prev - 1)
    }
  }
  const getThumbnailText = useMemo(() => {
    if (gallery.length === 1) {
      return thumbnailTexts.photoPage
    }
    return imgIdx === 0 ? thumbnailTexts.front : thumbnailTexts.back
  }, [gallery.length, imgIdx, thumbnailTexts])

  return (
    <div className="flex flex-col gap-3">
      <div
        className={`rounded-sm relative overflow-hidden w-full relative border border-tertiary-grey-200 ${className}`}
      >
        {!isImage.length || isImage[imgIdx] ? (
          <img
            src={gallery[imgIdx]}
            alt=""
            className="object-contain m-auto h-[100%]"
          />
        ) : (
          <>
            <div className="absolute w-full h-full" />
            <iframe
              src={gallery[imgIdx]}
              className="w-full h-full"
              title="uploaded-file"
            />
          </>
        )}

        <div className="h-7 w-7 rounded-sm bg-mono-white flex justify-center items-center absolute bottom-2 left-2 ">
          <FrankieButton
            intent="subtle"
            noStyles
            singleIcon={{
              name: 'mdiArrowExpand',

              size: 'xs',
            }}
            onClick={() => handleExpand(imgIdx)}
          />
        </div>
      </div>

      <div className="flex justify-between items-center w-full grow-0">
        <div className="border border-tertiary-grey-200 rounded-l-sm flex justify-center items-center grow-0">
          <FrankieButton
            intent="subtle"
            disabled={imgIdx === 0}
            className="h-7 !min-w-[28px]"
            singleIcon={{
              name: 'mdiChevronLeft',
              size: '2xs',
            }}
            onClick={handleImageChange(false)}
          />
        </div>
        <div className="font-medium text-xs leading-4 text-tertiary-grey-500">
          {getThumbnailText}
        </div>
        <div className="border border-tertiary-grey-200 rounded-r-sm flex justify-center items-center grow-0">
          <FrankieButton
            intent="subtle"
            className="h-7 !min-w-[28px]"
            disabled={imgIdx === gallery.length - 1}
            singleIcon={{
              name: 'mdiChevronRight',
              size: '2xs',
            }}
            onClick={handleImageChange(true)}
          />
        </div>
      </div>
    </div>
  )
}
