import React, { useEffect, useMemo } from 'react'

import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { FrankieButton, FrankieIcon } from 'frankify/src'

import { IndividualAmlResult } from 'features/individual-aml-result'

import { ApplicantId } from 'entities/applicant'
import {
  AmlStatusChangeForm,
  ProcessResultManualStatusEnumAML,
  useEntityDataQuery,
  useFrankie2R2Customer,
} from 'entities/entity'

import { Menu } from 'shared/components'
import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { INDIVIDUAL_AML_RESULT_PAGE_KEY } from '../individual-aml-result-page.key'
import { IndividualAmlResultPageEn } from '../locale/individual-aml-result-page.en'
import { getAmlStatusConfig } from '../model/individual-aml-result.model'
import { useAmlPagination } from '../state/individual-aml-result.query'

type Props = {
  applicantIdParamKey: ApplicantId
}

export function IndividualAmlResultPage({ applicantIdParamKey }: Props) {
  const t = useI18n(INDIVIDUAL_AML_RESULT_PAGE_KEY, {
    keys: IndividualAmlResultPageEn,
  })

  const params = useParams()

  const paramsEntityId = params[applicantIdParamKey]
  const [searchParams] = useSearchParams()
  const processResultId = searchParams.get('processResultId')

  const { data } = useEntityDataQuery(paramsEntityId)

  const isFrankie2 = useFrankie2R2Customer()

  const navigate = useNavigate()

  const [createOverlay, closeOverlay] = useOverlay()

  const {
    currentIndex,
    totalData,
    data: amlData,
    prev,
    next,
  } = useAmlPagination({
    entityId: paramsEntityId as string,
  })

  const manualStatus = useMemo(
    () =>
      amlData.processResults?.find(i => i.processResultId === processResultId)
        ?.manualStatus,
    [amlData, processResultId],
  ) as ProcessResultManualStatusEnumAML | undefined

  const handleBack = () => {
    navigate(-1)
  }

  useEffect(() => {
    trackingManager.track(TrackingEventsTypes.AmlResultViewIndividual)
  }, [])

  const { options, styles, text } = getAmlStatusConfig(t, manualStatus)

  function handleSelect(value: string) {
    createOverlay(
      <AmlStatusChangeForm
        statusValue={value}
        closeOverlay={closeOverlay}
        processResultId={[processResultId!]}
        restProcessResultIds={amlData.processResults
          ?.filter(pro => pro.processResultId !== processResultId)
          .map(pro => pro.processResultId)}
        entityId={paramsEntityId as string}
      />,
      {
        closeButtonClassName: '!top-6 !right-8',
        className: '!p-[20px]',
      },
    )
  }

  const handleClick = (type: 'prev' | 'next') => {
    let currentProcessResultId = searchParams.get('processResultId')
    if (!amlData.processResults) return

    if (type === 'prev') {
      currentProcessResultId = prev() ?? ''
    }
    if (type === 'next') {
      currentProcessResultId = next() ?? ''
    }

    if (currentProcessResultId) {
      searchParams.set('processResultId', currentProcessResultId)

      navigate({ search: searchParams.toString() }, { replace: true })
    }
  }

  if (!paramsEntityId || !isFrankie2) return null

  return (
    <div className=" z-0 relative max-h-[calc(100vh-70px)] overflow-y-auto scrollbar  ">
      <div className=" text-tertiary-grey-700 px-8 mb-24 mx-auto h-full">
        <div className="w-full mx-auto sticky bg-mono-white top-0 flex flex-col justify-items-start z-10 item-center pt-6 pb-4">
          <FrankieButton
            noStyles
            startIcon={{
              name: 'mdiArrowLeft',
              size: 'xs',
              className: 'text-tertiary-grey-500',
            }}
            onClick={handleBack}
            className="inline-flex gap-1"
          >
            <span className="text-xs text-tertiary-grey-500">{t('back')}</span>
          </FrankieButton>
          <div className="mt-4 flex gap-1 items-center">
            <div data-hj-suppress className=" text-xs text-tertiary-grey-600">
              {data?.individual?.name?.displayName}
            </div>
            <FrankieIcon
              className="text-tertiary-grey-400"
              name="mdiChevronRight"
              size="xs"
            />
            <div className="text-xs text-tertiary-grey-700">
              {data?.serviceProfiles?.at(0)?.currentWorkflowName}
            </div>
            <FrankieIcon
              className="text-tertiary-grey-400"
              name="mdiChevronRight"
              size="xs"
            />
            <div className="text-xs text-tertiary-grey-700">{t('title')} </div>
          </div>

          <div className="flex justify-between">
            <div className="pt-2 text-2xl font-bold text-tertiary-grey-800 leading-tight">
              {t('title')}
            </div>

            <div className="flex gap-4 flex-grow-0">
              <div className="flex gap-2 items-center shrink-0">
                <div className="text-sm leading-5 text-tertiary-grey-500">
                  {currentIndex + 1} of {totalData} matches
                </div>
                <div className="border border-tertiary-grey-200 flex bg-mono-white rounded-sm min-w-[96px]">
                  <FrankieButton
                    intent="subtle"
                    // disabled={currentIndex === 0}
                    disabled={currentIndex === 0}
                    size="xs"
                    className={`!rounded-none px-3 py-1 min-w-[50%] ${
                      currentIndex === 0 ? '!bg-tertiary-grey-200' : ''
                    }`}
                    onClick={() => handleClick('prev')}
                    singleIcon={{ name: 'mdiChevronLeft' }}
                  />
                  <FrankieButton
                    intent="subtle"
                    size="xs"
                    disabled={currentIndex + 1 === totalData}
                    onClick={() => handleClick('next')}
                    className={`!rounded-none px-3 py-1 min-w-[50%] ${
                      currentIndex === totalData ? '!bg-tertiary-grey-200' : ''
                    }`}
                    singleIcon={{ name: 'mdiChevronRight' }}
                  />
                </div>
              </div>
              <Menu
                onSelect={value => handleSelect(value)}
                options={options}
                icons={{ open: 'mdiChevronUp', close: 'mdiChevronDown' }}
                buttonClassName={`min-w-max !h-9 !rounded-sm  justify-between !text-xs focus:!outline-2 ${styles}`}
              >
                {text}
              </Menu>
            </div>
          </div>
        </div>

        <IndividualAmlResult entityId={paramsEntityId} />
      </div>
    </div>
  )
}
